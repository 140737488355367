import projectInfo from '../../../../package.json'

export const defaultEnvironmentSettings = {
  version: projectInfo.version,
  production: false,

  APP_API_URL: '/api',
  SEGMENT_KEY: 'XA2uDGxv86n38YIxOrwU0ZrZt32Kvael',
  MS_CLARITY_PROJECT_ID: null,
  GOOGLE_ANALYTICS_ID: null,

  mapbox: {
    accessToken:
      'pk.eyJ1IjoicWltaW5nZmFuZyIsImEiOiJjaXFjeHE5bjYwMmI1ZmpucDIwbDJ2c3BhIn0.1GyI_6KJ7H9dYNVluF6dSg',
  },

  CLOUDINARY_CLOUD_NAME: 'drkvqeha4',
  CLOUDINARY_API_KEY: '713937876124511',
}
