// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { defaultEnvironmentSettings } from './defaults'
import { Environment } from './models/environment'

export const environment = {
  ...defaultEnvironmentSettings,

  name: Environment.development,
  BASE_API_URL: 'https://fleet-customer-api.boosterio.us',
  ARMADA_URL: 'https://fleet.boosterio.us',
  FLEET_ORDER_MAP_WHITELIST: [] as any[],
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
