{
  "name": "fleet-customer",
  "version": "0.123.0",
  "license": "MIT",
  "scripts": {
    "start": "nx run-many -t serve --output-style=stream",
    "build": "nx build",
    "test": "nx test",
    "test:all": "nx run-many --target=test --all",
    "test:all-parallel": "nx run-many --target=test --all --parallel=6",
    "prepare": "husky install",
    "lint:eslint": "nx run-many --target=lint --all --parallel=6",
    "lint:prettier": "prettier --check .",
    "nx": "nx",
    "abacus": "nx run-many -t serve -p abacus-frontend abacus-api --output-style=stream",
    "armada": "nx run-many -t serve -p armada api --output-style=stream",
    "bsp": "nx run-many -t serve -p bsp-api bsp-frontend --output-style=stream",
    "bui": "nx run bui-docs:serve:local"
  },
  "private": true,
  "dependencies": {
    "@anatine/zod-nestjs": "2.0.8",
    "@anatine/zod-openapi": "1.11.0",
    "@angular/animations": "17.3.3",
    "@angular/cdk": "17.3.6",
    "@angular/common": "17.3.3",
    "@angular/compiler": "17.3.3",
    "@angular/core": "17.3.3",
    "@angular/forms": "17.3.8",
    "@angular/material": "17.3.3",
    "@angular/platform-browser": "17.3.3",
    "@angular/platform-browser-dynamic": "17.3.4",
    "@angular/router": "17.3.3",
    "@asymmetrik/ngx-leaflet": "17.0.0",
    "@boosterfuels/pubsub": "^1.5.0",
    "@boosterfuels/types": "^0.0.0-dev-development-HEAD-757841",
    "@google-cloud/pubsub": "^3.2.1",
    "@google-cloud/storage": "^5.18.2",
    "@googlemaps/google-maps-services-js": "^3.4.0",
    "@hubspot/api-client": "^11.1.0",
    "@mailchimp/mailchimp_transactional": "^1.0.50",
    "@nestjs/common": "^10.0.2",
    "@nestjs/core": "10.3.7",
    "@nestjs/event-emitter": "^1.3.1",
    "@nestjs/platform-express": "10.3.7",
    "@nestjs/swagger": "7.3.1",
    "@nestjs/terminus": "^10.0.1",
    "@ngrx/component": "17.1.0",
    "@ngrx/effects": "17.2.0",
    "@ngrx/router-store": "17.2.0",
    "@ngrx/store": "17.1.0",
    "@ngrx/store-devtools": "17.1.0",
    "@nx/angular": "18.2.2",
    "@sentry/angular-ivy": "7.75.1",
    "@sentry/node": "7.75.1",
    "@slack/bolt": "3.8.1",
    "@slack/web-api": "6.5.1",
    "@turf/centroid": "6.5.0",
    "@turf/distance": "^6.5.0",
    "apexcharts": "^3.49.1",
    "axios": "^0.28.0",
    "bson": "^4.6.0",
    "bulk-require": "^1.0.1",
    "cloudinary": "^1.34.0",
    "csv-stringify": "^6.0.5",
    "date-fns": "^2.29.3",
    "date-fns-tz": "^2.0.0",
    "dd-trace": "^4.16.0",
    "dotenv": "^16.4.5",
    "express-basic-auth": "^1.2.1",
    "file-saver": "^2.0.5",
    "form-data": "^4.0.0",
    "glpk.js": "^4.0.2",
    "gzipper": "^7.2.0",
    "html-to-image": "^1.11.11",
    "http-proxy-middleware": "^2.0.1",
    "http-server": "14.1.1",
    "leaflet": "1.9.4",
    "leaflet.markercluster": "1.5.3",
    "lodash": "^4.17.21",
    "moment": "^2.29.1",
    "moment-timezone": "^0.5.34",
    "mongodb": "^4.17.0",
    "mongodb-memory-server": "^9.0.1",
    "monogram": "npm:@boosterfuels/monogram@^1.0.1",
    "nest-winston": "^1.6.2",
    "nestjs-twilio": "^4.1.1",
    "ng-apexcharts": "1.7",
    "ngx-mask": "17.0.4",
    "openapi3-ts": "^4.3.1",
    "puppeteer": "^13.5.1",
    "reflect-metadata": "^0.2.2",
    "rxjs": "7.8.1",
    "semver": "7.5",
    "tslib": "^2.3.0",
    "uuid": "^10.0.0",
    "winston": "^3.4.0",
    "xlsx": "^0.18.5",
    "yazl": "^2.5.1",
    "zod": "^3.23.8",
    "zone.js": "0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.3.6",
    "@angular-devkit/core": "17.3.7",
    "@angular-devkit/schematics": "17.3.7",
    "@angular-eslint/eslint-plugin": "17.4.0",
    "@angular-eslint/eslint-plugin-template": "17.3.0",
    "@angular-eslint/template-parser": "17.4.0",
    "@angular/cli": "~17.3.0",
    "@angular/compiler-cli": "17.3.3",
    "@angular/language-service": "17.3.3",
    "@badeball/cypress-cucumber-preprocessor": "15.1.5",
    "@bahmutov/cypress-esbuild-preprocessor": "^2.1.5",
    "@boosterfuels/booster-tools": "1.0.3",
    "@faker-js/faker": "^8.4.1",
    "@nestjs/schematics": "^10.0.1",
    "@nestjs/testing": "10.3.7",
    "@nx/cypress": "18.2.4",
    "@nx/eslint": "18.3.4",
    "@nx/eslint-plugin": "18.3.5",
    "@nx/jest": "18.2.2",
    "@nx/js": "18.2.2",
    "@nx/nest": "18.2.2",
    "@nx/node": "18.3.4",
    "@nx/web": "18.2.2",
    "@nx/webpack": "18.2.2",
    "@nx/workspace": "18.2.2",
    "@schematics/angular": "17.3.3",
    "@swc-node/register": "~1.8.0",
    "@swc/core": "~1.3.85",
    "@swc/helpers": "~0.5.2",
    "@types/express": "^4.17.13",
    "@types/file-saver": "^2.0.5",
    "@types/jest": "29.4.4",
    "@types/leaflet": "1.9.12",
    "@types/leaflet.markercluster": "1.5.4",
    "@types/lodash": "^4.14.177",
    "@types/mailchimp__mailchimp_transactional": "^1.0.5",
    "@types/multer": "^1.4.7",
    "@types/node": "20.11.25",
    "@types/semver": "7.3",
    "@types/uuid": "^9.0.8",
    "@types/yazl": "^2.4.5",
    "@typescript-eslint/eslint-plugin": "7.9.0",
    "@typescript-eslint/parser": "7.8.0",
    "autoprefixer": "^10.4.20",
    "cypress": "13.9.0",
    "eslint": "8.57.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-cypress": "2.15.1",
    "husky": "^7.0.4",
    "jest": "29.4.3",
    "jest-environment-jsdom": "29.4.3",
    "jest-environment-node": "^29.4.1",
    "jest-junit": "^16.0.0",
    "jest-preset-angular": "14.0.3",
    "jsonc-eslint-parser": "^2.1.0",
    "lint-staged": "15.2.2",
    "marked": "^4.2.12",
    "ng-packagr": "17.3.0",
    "nx": "18.2.3",
    "postcss": "^8.4.41",
    "postcss-import": "~14.1.0",
    "postcss-preset-env": "~7.5.0",
    "postcss-url": "~10.1.3",
    "prettier": "3.2.5",
    "prettier-plugin-gherkin": "^2.2.1",
    "ts-jest": "29.1.2",
    "ts-node": "10.9.1",
    "typescript": "5.4.4",
    "webpack": "^5.64.0",
    "webpack-cli": "^5.1.4"
  },
  "overrides": {
    "@googlemaps/url-signature": "1.0.32"
  },
  "lint-staged": {
    "**/*": "prettier --write --ignore-unknown"
  },
  "config": {
    "mongodbMemoryServer": {
      "downloadDir": "./node_modules/mongodb-memory-server",
      "version": "6.0.12"
    }
  }
}
